import { createSlice } from "@reduxjs/toolkit";


const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        
        loading: false
    },
    reducers: {
        categoriesRequest(state, action){
            return {
                loading: true
            }
        },
        categoriesSuccess(state, action){
            return {
                loading: false,
                categories: action.payload.data,
            }
        },
        categoriesFail(state, action){
            return {
                loading: false,
                error:  action.payload
            }
        },
        subCategoriesRequest(state, action){
            return {
                loading: true
            }
        },
        subCategoriesSuccess(state, action){
            return {
                loading: false,
                subCategories: action.payload.data,
            }
        },
        subCategoriesFail(state, action){
            return {
                loading: false,
                error:  action.payload
            }
        }
    }
});

const { actions, reducer } = categoriesSlice;

export const { 
    categoriesRequest, 
    categoriesSuccess, 
    categoriesFail,
    subCategoriesRequest, 
    subCategoriesSuccess, 
    subCategoriesFail
} = actions;

export default reducer;
