import React, { useEffect, useState } from 'react';
import { Link,useNavigate  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuthError, login, register } from '../actions/userActions';
import { toast } from 'react-toastify';

const Account = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordRegister, setShowPasswordRegister] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [userData, setUserData] = useState({
        firstname: "",
        lastname: "",
        mobileno: "",
        whatsappno: "",
        email: "",
        password: "",
    });

    const onChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, error, isAuthenticated } = useSelector((state) => state.authState);

    const submitLoginHandler = (e) => {
        e.preventDefault();
        if (!email || !password) {
            toast.error("Please fill in both email and password.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        dispatch(login(email, password));
    };

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/profile')
        }

        if (error) {
            toast(error, {
                position: toast.POSITION.TOP_RIGHT,
                type: "error",
                onOpen: () => {
                    dispatch(clearAuthError);
                },
            });
        }
    }, [error, isAuthenticated, dispatch,navigate]);

    const submitRegisterHandler = (e) => {
        e.preventDefault();

        // Validation
        if (!userData.firstname || !userData.lastname || !userData.email || !userData.password || !userData.mobileno) {
            toast.error('Please fill in all required fields.', {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        const registerData = {
            first_name : userData.firstname,
            last_name : userData.lastname,
            mobile_no : userData.mobileno,
            whatsapp_no : userData.whatsappno,
            email_id : userData.email,
            password : userData.password
        }
        dispatch(register(registerData));
    };

    return (
        <section className="account py-80">
            <div className="container container-lg">
                <div className="row gy-4">
                    {/* Login Card */}
                    <div className="col-xl-6 pe-xl-5">
                        <div className="shadow-lg border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40 h-100">
                            <h6 className="text-xl mb-32">Login</h6>
                            <div className="mb-24">
                                <label
                                    htmlFor="mail_id"
                                    className="text-neutral-900 text-lg mb-8 fw-medium"
                                >
                                   Email ID <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="common-input"
                                    id="mail_id"
                                    placeholder="Enter Email"
                                    autoComplete="off"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="mb-24">
                                <label
                                    htmlFor="password"
                                    className="text-neutral-900 text-lg mb-8 fw-medium"
                                >
                                    Password
                                </label>
                                <div className="position-relative">
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        className="common-input"
                                        id="password"
                                        placeholder="Enter Password"
                                        value={password}
                                        autoComplete="off"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <span
                                        onClick={() => setShowPassword(!showPassword)}
                                        className={`toggle-password position-absolute top-50 inset-inline-end-0 me-16 translate-middle-y cursor-pointer ${showPassword ? 'ph-eye' : 'ph-eye-slash'}`}
                                        id="toggle-password"
                                    />
                                </div>
                            </div>
                            <div className="mb-24 mt-48">
                                <div className="flex-align gap-48 flex-wrap">
                                    <button type="button" onClick={submitLoginHandler} className="btn btn-main py-18 px-40">
                                        {loading ? 'Loading...' : 'Log in'}
                                    </button>
                                </div>
                            </div>
                            <div className="mt-48">
                                <Link
                                    to="#"
                                    className="text-danger-600 text-sm fw-semibold hover-text-decoration-underline"
                                >
                                    Forgot your password?
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* Register Card */}
                    <div className="col-xl-6">
                        <div className="shadow-lg border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40 h-100">
                            <h6 className="text-xl mb-32">Register</h6>
                            <form onSubmit={submitRegisterHandler}>
                                <div className="row">
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="firstnameTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            First Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="firstnameTwo"
                                            name="firstname"
                                            value={userData.firstname}
                                            placeholder="Write a firstname"
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="lastnameTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Last Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="lastnameTwo"
                                            name="lastname"
                                            value={userData.lastname}
                                            placeholder="Write a lastname"
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="emailTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Email address <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            className="common-input"
                                            id="emailTwo"
                                            name="email"
                                            value={userData.email}
                                            placeholder="Enter Email Address"
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="mobile_noTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Mobile No. <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="mobile_noTwo"
                                            name="mobileno"
                                            value={userData.mobileno}
                                            placeholder="Write a mobile no"
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="whatsapp_noTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Whatsapp No 
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="whatsapp_noTwo"
                                            name="whatsappno"
                                            value={userData.whatsappno}
                                            placeholder="Write a whatsapp no"
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="enter-password"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Password <span className="text-danger">*</span>
                                        </label>
                                        <div className="position-relative">
                                            <input
                                                type={showPasswordRegister ? 'text' : 'password'}
                                                className="common-input"
                                                id="enter-password"
                                                name="password"
                                                value={userData.password}
                                                placeholder="Enter Password"
                                                onChange={onChange}
                                            />
                                            <span
                                                onClick={() => setShowPasswordRegister(!showPasswordRegister)}
                                                className={`toggle-password position-absolute top-50 inset-inline-end-0 me-16 translate-middle-y cursor-pointer ${showPasswordRegister ? 'ph-eye' : 'ph-eye-slash'}`}
                                                id="toggle-register-password"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-48">
                                    <button type="submit" className="btn btn-main py-18 px-40">
                                        Register
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Account;
