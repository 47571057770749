import axios from 'axios';
import { productsFail, productsSuccess, productsRequest,productDetailFail, productDetailSuccess, productDetailRequest} from '../slices/productsSlice';
export const getProducts = (page=1,category_id,price,colour) => async (dispatch) => {
    try {  
        dispatch(productsRequest()) 
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        var link = `${process.env.REACT_APP_API_URL}website/product_list/?page=${page}`;
        if(category_id) {
            link += `&category_id=${category_id}`
        }
        if(price) {
            link += `&price[gte]=${price[0]}&price[lte]=${price[1]}`
        }
        if(colour) {
            link += `&colour_id=${colour}`
        }
        const { data }  =  await axios.get(link,config);
        dispatch(productsSuccess(data))
    } catch (error) {
        console.log(error);
        dispatch(productsFail(error.response))
    }
}

export const getProductDetail = (name) => async (dispatch) => {
    try {  
        dispatch(productDetailRequest()) 
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        const { data }  =  await axios.get(`${process.env.REACT_APP_API_URL}website/product_detail/${name}`,config);
        dispatch(productDetailSuccess(data))
    } catch (error) {
        dispatch(productDetailFail(error.response.data.message))
    }
}
