import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import productsReducer from "./slices/productsSlice";
import categoriesReducer from "./slices/categoriesSlice";
import authReducer from "./slices/authSlice";
import cartReducer from './slices/cartSlice';
const reducer = combineReducers({
    productsState: productsReducer,
    categoriesState:categoriesReducer,
    authState: authReducer,
    cartState: cartReducer,
})

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
})

export default store;