import axios from 'axios';
import { categoriesFail, categoriesSuccess, categoriesRequest,subCategoriesFail, subCategoriesSuccess, subCategoriesRequest} from '../slices/categoriesSlice';
export const getCategories = () => async (dispatch) => {
    try {  
        dispatch(categoriesRequest()) 
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        const { data }  =  await axios.get(`${process.env.REACT_APP_API_URL}website/category_list`,config);
        dispatch(categoriesSuccess(data))
    } catch (error) {
        dispatch(categoriesFail(error.response.data.message))
    }
}

export const getSubCategories = () => async (dispatch) => {
    try {  
        dispatch(subCategoriesRequest()) 
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        const { data }  =  await axios.get(`${process.env.REACT_APP_API_URL}website/sub_category_list`,config);
        dispatch(subCategoriesSuccess(data))
    } catch (error) {
        dispatch(subCategoriesFail(error.response.data.message))
    }
}
