import { BrowserRouter, Route, Routes } from "react-router-dom";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import HomePageOne from "./pages/HomePageOne";
import PhosphorIconInit from "./helper/PhosphorIconInit";
import HomePageTwo from "./pages/HomePageTwo";
import ShopPage from "./pages/ShopPage";
import ProductDetailsPageOne from "./pages/ProductDetailsPageOne";
import ProductDetailsPageTwo from "./pages/ProductDetailsPageTwo";
import CartPage from "./pages/CartPage";
import CheckoutPage from "./pages/CheckoutPage";
import AccountPage from "./pages/AccountPage";
import ProfilePage from "./pages/ProfilePage";
import BlogPage from "./pages/BlogPage";
import BlogDetailsPage from "./pages/BlogDetailsPage";
import ContactPage from "./pages/ContactPage";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import store from './store';
import { loadUser } from "./actions/userActions";

function App() {
  useEffect(() => {
    store.dispatch(loadUser)
  }, []);
  return (
    <BrowserRouter>
      <ToastContainer />
      <RouteScrollToTop />
      <PhosphorIconInit />
      <HelmetProvider>
        <Routes>
          <Route exact path="/" element={<HomePageOne />} />
          <Route exact path="/index-two" element={<HomePageTwo />} />
          <Route exact path="/shop" element={<ShopPage />} />
          <Route exact path="/product/:name" element={<ProductDetailsPageOne />} />
          <Route exact path="/product-details-two" element={<ProductDetailsPageTwo />} />
          <Route exact path="/cart" element={<CartPage />} />
          <Route exact path="/checkout" element={<CheckoutPage />} />
          <Route exact path="/account" element={<AccountPage />} />
          <Route exact path="/profile" element={<ProfilePage />} />
          <Route exact path="/blog" element={<BlogPage />} />
          <Route exact path="/blog-details" element={<BlogDetailsPage />} />
          <Route exact path="/contact" element={<ContactPage />} />

        </Routes>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
