import {addCartItemRequest, addCartItemSuccess} from '../slices/cartSlice';
import axios from 'axios'

export const addCartItem = (product_id, quantity) => async(dispatch) => {
    try {
        dispatch(addCartItemRequest())
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        const {data } = await axios.get(`${process.env.REACT_APP_API_URL}website/product_view/${product_id}`,config)
        const result = data.data;
        dispatch(addCartItemSuccess({
            product_id: result.product_id_fk,
            product_code : result.product_code,
            product_name: result.product_name,
            product_option_id : result.product_option_id,
            colour_id_fk : result.colour_id_fk,
            colour_name : result.colour_name,
            current_stock : result.current_stock,
            list_price : result.list_price,
            list_single_price : result.list_single_price,
            selling_price : result.selling_price,
            selling_single_price : result.selling_single_price,
            product_image : result.product_image,
            quantity
        }))
    } catch (error) {
        
    }
}