import React from 'react'
import { Link } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import { decreaseCartItemQty, increaseCartItemQty,removeItemFromCart } from '../slices/cartSlice';

const CartSection = () => {
    const {items } = useSelector(state => state.cartState)
    console.log(items);
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const increaseQty = (item) => {
        const count = item.quantity;
        if(item.stock ===0 ||  count >= item.stock) return;
        dispatch(increaseCartItemQty(item.product_option_id))
    }
    const decreaseQty = (item) => {
        const count = item.quantity;
        if(count === 1) return;
        dispatch(decreaseCartItemQty(item.product_option_id))
    }
    return (
        <section className="cart py-80">
            <div className="container container-lg">
                <div className="row gy-4">
                {   items.length >0 ?(
                    <>
                        <div className="col-xl-9 col-lg-8">
                            <div className="cart-table border border-gray-100 rounded-8 px-40 py-48">
                                <div className="overflow-x-auto scroll-sm scroll-sm-horizontal">
                                    <table className="table style-three">
                                        <thead>
                                            <tr>
                                                <th className="h6 mb-0 text-lg fw-bold">Delete</th>
                                                <th className="h6 mb-0 text-lg fw-bold">Product Name</th>
                                                <th className="h6 mb-0 text-lg fw-bold">Price</th>
                                                <th className="h6 mb-0 text-lg fw-bold">Quantity</th>
                                                <th className="h6 mb-0 text-lg fw-bold">Subtotal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {items.map(item => (
                                            <tr key={item.product_option_id}>
                                                <td>
                                                    <button
                                                    onClick={() => dispatch(removeItemFromCart(item.product_option_id))}
                                                        type="button"
                                                        className="remove-tr-btn flex-align gap-12 hover-text-danger-600"
                                                    >
                                                        <i className="ph ph-x-circle text-2xl d-flex" />
                                                        Remove
                                                    </button>
                                                </td>
                                                <td>
                                                    <div className="table-product d-flex align-items-center gap-24">
                                                        <Link
                                                            to="/product-details-two"
                                                            className="table-product__thumb border border-gray-100 rounded-8 flex-center "
                                                        >
                                                            <img
                                                                src={process.env.REACT_APP_API_URL + item.product_image}
                                                                alt=""
                                                            />
                                                        </Link>
                                                        <div className="table-product__content text-start">
                                                            <h6 className="title text-lg fw-semibold mb-8">
                                                                <Link
                                                                    to="/product-details"
                                                                    className="link text-line-2"
                                                                    tabIndex={0}
                                                                >
                                                                    {item.product_name} {item.product_code}
                                                                </Link>
                                                            </h6>
                                                            <div className="flex-align gap-16">
                                                                <Link
                                                                    to="/cart"
                                                                    className="product-card__cart btn bg-gray-50 text-heading text-sm hover-bg-main-600 hover-text-white py-7 px-8 rounded-8 flex-center gap-8 fw-medium"
                                                                >
                                                                    {item.colour_name}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="text-lg h6 mb-0 fw-semibold">{item.selling_price}</span>
                                                </td>
                                                <td>
                                                    <div className="d-flex rounded-4 overflow-hidden">
                                                        <button
                                                            type="button"
                                                            onClick={() => decreaseQty(item)}
                                                            className="quantity__minus border border-end border-gray-100 flex-shrink-0 h-48 w-48 text-neutral-600 flex-center hover-bg-main-600 hover-text-white"
                                                        >
                                                            <i className="ph ph-minus" />
                                                        </button>
                                                        <input
                                                            type="number"
                                                            className="quantity__input flex-grow-1 border border-gray-100 border-start-0 border-end-0 text-center w-32 px-4"
                                                            value={item.quantity}
                                                            min={1}
                                                            readOnly
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={() => increaseQty(item)}
                                                            className="quantity__plus border border-end border-gray-100 flex-shrink-0 h-48 w-48 text-neutral-600 flex-center hover-bg-main-600 hover-text-white"
                                                        >
                                                            <i className="ph ph-plus" />
                                                        </button>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="text-lg h6 mb-0 fw-semibold">{item.selling_price*item.quantity}</span>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex-between flex-wrap gap-16 mt-16">
                                    <div className="flex-align gap-16">
                                        <input
                                            type="text"
                                            className="common-input"
                                            placeholder="Coupon Code"
                                        />
                                        <button
                                            type="submit"
                                            className="btn btn-main py-18 w-100 rounded-8"
                                        >
                                            Apply Coupon
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4">
                            <div className="cart-sidebar border border-gray-100 rounded-8 px-24 py-40">
                                <h6 className="text-xl mb-32">Cart Totals</h6>
                                <div className="bg-color-three rounded-8 p-24">
                                    <div className="mb-32 flex-between gap-8">
                                        <span className="text-gray-900 font-heading-two">Subtotal</span>
                                        <span className="text-gray-900 fw-semibold">{items.reduce((acc, item)=>(acc + item.quantity * item.selling_price), 0)}</span>
                                    </div>
                                    <div className="mb-32 flex-between gap-8">
                                        <span className="text-gray-900 font-heading-two">
                                            Extimated Delivery
                                        </span>
                                        <span className="text-gray-900 fw-semibold">Free</span>
                                    </div>
                                </div>
                                <div className="bg-color-three rounded-8 p-24 mt-24">
                                    <div className="flex-between gap-8">
                                        <span className="text-gray-900 text-xl fw-semibold">Total</span>
                                        <span className="text-gray-900 text-xl fw-semibold">{items.reduce((acc, item)=>(acc + item.quantity * item.selling_price), 0)}</span>
                                    </div>
                                </div>
                                <Link
                                    to="/checkout"
                                    className="btn btn-main mt-40 py-18 w-100 rounded-8"
                                >
                                    Proceed to checkout
                                </Link>
                            </div>
                        </div>
                    </>
                ):''
                }
                </div>
            </div>
        </section>

    )
}

export default CartSection