import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { getCountdown } from '../helper/Countdown';


const SampleNextArrow = memo(function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <button
            type="button" onClick={onClick}
            className={` ${className} slick-next slick-arrow flex-center rounded-circle border border-gray-100 hover-border-neutral-600 text-xl hover-bg-neutral-600 hover-text-white transition-1`}
        >
            <i className="ph ph-caret-right" />
        </button>
    );
});

const SamplePrevArrow = memo(function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <button
            type="button"
            onClick={onClick}
            className={`${className} slick-prev slick-arrow flex-center rounded-circle border border-gray-100 hover-border-neutral-600 text-xl hover-bg-neutral-600 hover-text-white transition-1`}
        >
            <i className="ph ph-caret-left" />
        </button>
    );
});

const DealsOne = () => {
    const [timeLeft, setTimeLeft] = useState(getCountdown());

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(getCountdown());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1599,
                settings: {
                    slidesToShow: 5,

                },
            },
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 3,

                },
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,

                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,

                },
            },

        ],
    };
    return ( 
        <section className="deals-weeek pt-80">
            <div className="container container-lg">
                <div className="border border-gray-100 p-24 rounded-16">
                    <div className="section-heading mb-24">
                        <div className="flex-between flex-wrap gap-8">
                            <h5 className="mb-0">Latest Collections</h5>
                            <div className="flex-align mr-point gap-16">
                                <Link
                                    to="/shop "
                                    className="text-sm fw-medium text-gray-700 hover-text-main-600 hover-text-decoration-underline"
                                >
                                    View All Deals
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="deals-week-slider arrow-style-two">
                        <Slider {...settings}>
                            <div>
                                <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                                    <Link
                                        to="/product-details-two"
                                        className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                                    >
                                        <span className="product-card__badge bg-main-600 px-8 py-4 text-sm text-white position-absolute inset-inline-start-0 inset-block-start-0">
                                            Sold
                                        </span>
                                        <img
                                            src="assets/images/thumbs/product-two-img1.png"
                                            alt=""
                                            className="w-auto max-w-unset"
                                        />
                                    </Link>
                                    <div className="product-card__content mt-16">
                                        
                                        <h6 className="title text-lg fw-semibold mt-12 mb-8">
                                            <Link
                                                to="/product-details-two"
                                                className="link text-line-2"
                                                tabIndex={0}
                                            >
                                                Golden Pie Unicorn Kids Pendant with Link Chain
                                            </Link>
                                        </h6>
                                        
                                       
                                        <div className="product-card__price my-20">
                                            <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                                                $28.99
                                            </span>
                                            <span className="text-heading text-md fw-semibold ">
                                                $14.99 
                                            </span>
                                        </div>
                                        <Link
                                            to="/cart"
                                            className="product-card__cart btn bg-gray-50 text-heading hover-bg-main-600 hover-text-white py-11 px-24 rounded-8 flex-center gap-8 fw-medium"
                                            tabIndex={0}
                                        >
                                            Add To Cart <i className="ph ph-shopping-cart" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                                    <Link
                                        to="/product-details-two"
                                        className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                                    >
                                        <span className="product-card__badge bg-danger-600 px-8 py-4 text-sm text-white position-absolute inset-inline-start-0 inset-block-start-0">
                                            Sale 50%{" "}
                                        </span>
                                        <img
                                            src="assets/images/thumbs/product-two-img2.png"
                                            alt=""
                                            className="w-auto max-w-unset"
                                        />
                                    </Link>
                                    <div className="product-card__content mt-16">
                                        
                                        <h6 className="title text-lg fw-semibold mt-12 mb-8">
                                            <Link
                                                to="/product-details-two"
                                                className="link text-line-2"
                                                tabIndex={0}
                                            >
                                                Golden Pie Unicorn Kids Pendant with Link Chain
                                            </Link>
                                        </h6>
                                        
                                       
                                        <div className="product-card__price my-20">
                                            <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                                                $28.99
                                            </span>
                                            <span className="text-heading text-md fw-semibold ">
                                                $14.99 
                                            </span>
                                        </div>
                                        <Link
                                            to="/cart"
                                            className="product-card__cart btn bg-gray-50 text-heading hover-bg-main-600 hover-text-white py-11 px-24 rounded-8 flex-center gap-8 fw-medium"
                                            tabIndex={0}
                                        >
                                            Add To Cart <i className="ph ph-shopping-cart" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                                    <Link
                                        to="/product-details-two"
                                        className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                                    >
                                        <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white position-absolute inset-inline-start-0 inset-block-start-0">
                                            New
                                        </span>
                                        <img
                                            src="assets/images/thumbs/product-two-img3.png"
                                            alt=""
                                            className="w-auto max-w-unset"
                                        />
                                    </Link>
                                    <div className="product-card__content mt-16">
                                        
                                        <h6 className="title text-lg fw-semibold mt-12 mb-8">
                                            <Link
                                                to="/product-details-two"
                                                className="link text-line-2"
                                                tabIndex={0}
                                            >
                                                Golden Pie Unicorn Kids Pendant with Link Chain
                                            </Link>
                                        </h6>
                                        
                                       
                                        <div className="product-card__price my-20">
                                            <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                                                $28.99
                                            </span>
                                            <span className="text-heading text-md fw-semibold ">
                                                $14.99 
                                            </span>
                                        </div>
                                        <Link
                                            to="/cart"
                                            className="product-card__cart btn bg-gray-50 text-heading hover-bg-main-600 hover-text-white py-11 px-24 rounded-8 flex-center gap-8 fw-medium"
                                            tabIndex={0}
                                        >
                                            Add To Cart <i className="ph ph-shopping-cart" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                                    <Link
                                        to="/product-details-two"
                                        className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                                    >
                                        <span className="product-card__badge bg-tertiary-600 px-8 py-4 text-sm text-white position-absolute inset-inline-start-0 inset-block-start-0">
                                            Best seller
                                        </span>
                                        <img
                                            src="assets/images/thumbs/product-two-img4.png"
                                            alt=""
                                            className="w-auto max-w-unset"
                                        />
                                    </Link>
                                    <div className="product-card__content mt-16">
                                        
                                        <h6 className="title text-lg fw-semibold mt-12 mb-8">
                                            <Link
                                                to="/product-details-two"
                                                className="link text-line-2"
                                                tabIndex={0}
                                            >
                                                Golden Pie Unicorn Kids Pendant with Link Chain
                                            </Link>
                                        </h6>
                                        
                                       
                                        <div className="product-card__price my-20">
                                            <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                                                $28.99
                                            </span>
                                            <span className="text-heading text-md fw-semibold ">
                                                $14.99 
                                            </span>
                                        </div>
                                        <Link
                                            to="/cart"
                                            className="product-card__cart btn bg-gray-50 text-heading hover-bg-main-600 hover-text-white py-11 px-24 rounded-8 flex-center gap-8 fw-medium"
                                            tabIndex={0}
                                        >
                                            Add To Cart <i className="ph ph-shopping-cart" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                                    <Link
                                        to="/product-details-two"
                                        className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                                    >
                                        <span className="product-card__badge bg-main-600 px-8 py-4 text-sm text-white position-absolute inset-inline-start-0 inset-block-start-0">
                                            Best Seller{" "}
                                        </span>
                                        <img
                                            src="assets/images/thumbs/product-two-img5.png"
                                            alt=""
                                            className="w-auto max-w-unset"
                                        />
                                    </Link>
                                    <div className="product-card__content mt-16">
                                        
                                        <h6 className="title text-lg fw-semibold mt-12 mb-8">
                                            <Link
                                                to="/product-details-two"
                                                className="link text-line-2"
                                                tabIndex={0}
                                            >
                                                Golden Pie Unicorn Kids Pendant with Link Chain
                                            </Link>
                                        </h6>
                                        
                                       
                                        <div className="product-card__price my-20">
                                            <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                                                $28.99
                                            </span>
                                            <span className="text-heading text-md fw-semibold ">
                                                $14.99 
                                            </span>
                                        </div>
                                        <Link
                                            to="/cart"
                                            className="product-card__cart btn bg-gray-50 text-heading hover-bg-main-600 hover-text-white py-11 px-24 rounded-8 flex-center gap-8 fw-medium"
                                            tabIndex={0}
                                        >
                                            Add To Cart <i className="ph ph-shopping-cart" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                                    <Link
                                        to="/product-details-two"
                                        className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                                    >
                                        <span className="product-card__badge bg-tertiary-600 px-8 py-4 text-sm text-white position-absolute inset-inline-start-0 inset-block-start-0">
                                            Best seller
                                        </span>
                                        <img
                                            src="assets/images/thumbs/product-two-img6.png"
                                            alt=""
                                            className="w-auto max-w-unset"
                                        />
                                    </Link>
                                    <div className="product-card__content mt-16">
                                        
                                        <h6 className="title text-lg fw-semibold mt-12 mb-8">
                                            <Link
                                                to="/product-details-two"
                                                className="link text-line-2"
                                                tabIndex={0}
                                            >
                                                Golden Pie Unicorn Kids Pendant with Link Chain
                                            </Link>
                                        </h6>
                                        
                                       
                                        <div className="product-card__price my-20">
                                            <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                                                $28.99
                                            </span>
                                            <span className="text-heading text-md fw-semibold ">
                                                $14.99 
                                            </span>
                                        </div>
                                        <Link
                                            to="/cart"
                                            className="product-card__cart btn bg-gray-50 text-heading hover-bg-main-600 hover-text-white py-11 px-24 rounded-8 flex-center gap-8 fw-medium"
                                            tabIndex={0}
                                        >
                                            Add To Cart <i className="ph ph-shopping-cart" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                                    <Link
                                        to="/product-details-two"
                                        className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                                    >
                                        <span className="product-card__badge bg-warning-600 px-8 py-4 text-sm text-white position-absolute inset-inline-start-0 inset-block-start-0">
                                            New
                                        </span>
                                        <img
                                            src="assets/images/thumbs/product-two-img9.png"
                                            alt=""
                                            className="w-auto max-w-unset"
                                        />
                                    </Link>
                                    <div className="product-card__content mt-16">
                                        
                                        <h6 className="title text-lg fw-semibold mt-12 mb-8">
                                            <Link
                                                to="/product-details-two"
                                                className="link text-line-2"
                                                tabIndex={0}
                                            >
                                                Golden Pie Unicorn Kids Pendant with Link Chain
                                            </Link>
                                        </h6>
                                        
                                       
                                        <div className="product-card__price my-20">
                                            <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                                                $28.99
                                            </span>
                                            <span className="text-heading text-md fw-semibold ">
                                                $14.99 
                                            </span>
                                        </div>
                                        <Link
                                            to="/cart"
                                            className="product-card__cart btn bg-gray-50 text-heading hover-bg-main-600 hover-text-white py-11 px-24 rounded-8 flex-center gap-8 fw-medium"
                                            tabIndex={0}
                                        >
                                            Add To Cart <i className="ph ph-shopping-cart" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default DealsOne