import axios from 'axios';
import { 
    loginFail, 
    loginSuccess, 
    loginRequest,
    clearError,
    registerFail, 
    registerSuccess, 
    registerRequest,
    loadUserRequest,
    loadUserSuccess,
    loadUserFail,
    logoutSuccess,
    logoutFail,
    updateProfileRequest,
    updateProfileSuccess,
    updateProfileFail,
    resetPasswordFail,
    resetPasswordRequest,
    resetPasswordSuccess,
    listAddressFail,
    listAddressRequest,
    listAddressSuccess,
    addAddressFail,
    addAddressRequest,
    addAddressSuccess,
    deleteAddressFail,
    deleteAddressRequest,
    deleteAddressSuccess,
    editAddressFail,
    editAddressRequest,
    editAddressSuccess,
    listStateFail,
    listStateRequest,
    listStateSuccess,
    listCountryFail,
    listCountryRequest,
    listCountrySuccess,
} from '../slices/authSlice';
import { toast } from 'react-toastify';
export const login = (username,password) => async (dispatch) => {

    try {  
        dispatch(loginRequest()) 
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers',
            }
        };
        const { data }  =  await axios.post('website',{username,password},config);
        if(data.status === 200){
            dispatch(loginSuccess(data))
        }else{
            dispatch(loginFail(data.message))
        }
    } catch (error) {
        dispatch(loginFail(error.response ? error.response.data.message : error.message))
    }
    
}

export const clearAuthError = dispatch => {
    dispatch(clearError()) 
}

export const register = (userData) => async (dispatch) => {

    try {  
        dispatch(registerRequest()) 
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        const { data }  =  await axios.post('website/registration',userData,config);
        if(data.status === 200){
            dispatch(registerSuccess(data))
        }else{
            dispatch(registerFail(data.message))
        }
    } catch (error) {
        dispatch(registerFail(error.response ? error.response.data.message : error.message))
    }
    
}
export const loadUser =  async (dispatch) => {

    try {
        dispatch(loadUserRequest())
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        const { data }  = await axios.get('website/myprofile',config);
        dispatch(loadUserSuccess(data))
    } catch (error) {
        dispatch(loadUserFail(error.response ? error.response.data.message : error.message))
    }

}
export const logout =  async (dispatch) => {

    try {
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        const { data }  = await axios.get('website/logout',config);
        if(data.status === 200){
            toast.success(data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            dispatch(logoutSuccess(data))
        }else{
            toast.error(data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            dispatch(logoutFail(data.message))
        }
        
    } catch (error) {
        dispatch(logoutFail(error.response ? error.response.data.message : error.message))
    }

}


export const updateProfile = (userData) => async (dispatch) => {

    try {
        dispatch(updateProfileRequest())
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };

        const { data }  = await axios.put('website/update_profile',userData, config);
        dispatch(updateProfileSuccess(data))
    } catch (error) {
        dispatch(updateProfileFail(error.response ? error.response ? error.response.data.message : error.message : error.message))
    }

}
export const resetPassword = (password,confirmPassword) => async (dispatch) => {

    try {
        dispatch(resetPasswordRequest())
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        }
        const { data} =  await axios.post('website/reset_password', {password,confirmPassword}, config);
        if(data.status === 200){
            toast.success(data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            dispatch(resetPasswordSuccess(data))
        }else{
            toast.error(data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            dispatch(resetPasswordFail(data.message))
        }
    } catch (error) {
        dispatch(resetPasswordFail(error.response ? error.response.data.message : error.message))
    }

}

export const getAddress =  async (dispatch) => {
    try {
        dispatch(listAddressRequest())
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        const { data }  = await axios.get('website/get_address',config);
        dispatch(listAddressSuccess(data))
    } catch (error) {
        dispatch(listAddressFail(error.response ? error.response.data.message : error.message))
    }
}

export const addAddress = (addressData) => async (dispatch) => {

    try {  
        dispatch(addAddressRequest()) 
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        const { data }  =  await axios.post('website/add_address',addressData,config);
        if(data.status === 200){
            dispatch(addAddressSuccess(data))
        }else{
            dispatch(addAddressFail(data.message))
        }
    } catch (error) {
        dispatch(addAddressFail(error.response ? error.response.data.message : error.message))
    }
    
}

export const deleteAddress = (address_id) => async (dispatch) => {

    try {  
        dispatch(deleteAddressRequest()) 
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        const { data }  =  await axios.post('website/delete_address',{address_id},config);
        if(data.status === 200){
            dispatch(deleteAddressSuccess(data))
        }else{
            dispatch(deleteAddressFail(data.message))
        }
    } catch (error) {
        dispatch(deleteAddressFail(error.response ? error.response.data.message : error.message))
    }
    
}

export const editAddress = (editaddressData) => async (dispatch) => {

    try {  
        dispatch(editAddressRequest()) 
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        const { data }  =  await axios.post(`${process.env.REACT_APP_API_URL}website/edit_address`,editaddressData,config);
        if(data.status === 200){
            dispatch(editAddressSuccess(data))
        }else{
            dispatch(editAddressFail(data.message))
        }
    } catch (error) {
        dispatch(editAddressFail(error.response ? error.response.data.message : error.message))
    }
    
}

export const getStateList =  async (dispatch) => {
    try {
        dispatch(listStateRequest())
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        const { data }  = await axios.get(`${process.env.REACT_APP_API_URL}website/get_state`,config);
        dispatch(listStateSuccess(data))
    } catch (error) {
        dispatch(listStateFail(error.response ? error.response.data.message : error.message))
    }
}
export const getCountryList =  async (dispatch) => {
    try {
        dispatch(listCountryRequest())
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        const { data }  = await axios.get(`${process.env.REACT_APP_API_URL}website/get_country`,config);
        dispatch(listCountrySuccess(data))
    } catch (error) {
        dispatch(listCountryFail(error.response ? error.response.data.message : error.message))
    }
}


