import { createSlice } from "@reduxjs/toolkit";


const productsSlice = createSlice({
    name: 'products',
    initialState: {
        loading: false
    },
    reducers: {
        productsRequest(state, action){
            return {
                loading: true
            }
        },
        productsSuccess(state, action){
            return {
                loading: false,
                products: action.payload.data.product,
                categories: action.payload.data.categories,
                colour_lists :action.payload.data.colour_lists,
                minPrice :action.payload.data.minPrice,
                maxPrice :action.payload.data.maxPrice,
                selectedminPrice :action.payload.data.selectedminPrice,
                selectedmaxPrice :action.payload.data.selectedmaxPrice,
                product_count : action.payload.data.product_count,
            }
        },
        productsFail(state, action){
            return {
                loading: false,
                error:  action.payload
            }
        },
        productDetailRequest(state, action){
            return {
                loading: true
            }
        },
        productDetailSuccess(state, action){
            return {
                loading: false,
                product_detail: action.payload.data,
            }
        },
        productDetailFail(state, action){
            return {
                loading: false,
                error:  action.payload
            }
        }
    }
});

const { actions, reducer } = productsSlice;

export const { 
    productsRequest, 
    productsSuccess, 
    productsFail,
    productDetailRequest, 
    productDetailSuccess, 
    productDetailFail
} = actions;

export default reducer;
