import { createSlice } from "@reduxjs/toolkit";


const authSlice = createSlice({
    name: 'auth',
    initialState: {
        loading: false,
        isAuthenticated: false
    },
    reducers: {
        loginRequest(state, action){
            return {
                ...state,
                loading: true,
            }
        },
        loginSuccess(state, action){
            return {
                loading: false,
                isAuthenticated: true,
                user: action.payload.data
            }
        },
        loginFail(state, action){
            return {
                ...state,
                loading: false,
                error:  action.payload
            }
        },
        clearError(state, action){
            return {
                ...state,
                error:  null
            }
        },
        registerRequest(state, action){
            return {
                ...state,
                loading: true,
            }
        },
        registerSuccess(state, action){
            return {
                loading: false,
                isAuthenticated: true,
                user: action.payload.data
            }
        },
        registerFail(state, action){
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error:  action.payload
            }
        },
        loadUserRequest(state, action){
            return {
                ...state,
                isAuthenticated: false,
                loading: true,
            }
        },
        loadUserSuccess(state, action){
            return {
                loading: false,
                isAuthenticated: true,
                user: action.payload.data
            }
        },
        loadUserFail(state, action){
            return {
                ...state,
                loading: false,
            }
        },
        logoutSuccess(state, action){
            return {
                loading: false,
                isAuthenticated: false,
            }
        },
        logoutFail(state, action){
            return {
                ...state,
                error:  action.payload
            }
        },
        updateProfileRequest(state, action){
            return {
                ...state,
                loading: true,
                isUpdated: false
            }
        },
        updateProfileSuccess(state, action){
            return {
                ...state,
                loading: false,
                user: action.payload.data,
                isUpdated: true
            }
        },
        updateProfileFail(state, action){
            return {
                ...state,
                loading: false,
                error:  action.payload
            }
        },
        clearUpdateProfile(state, action){
            return {
                ...state,
                isUpdated: false
            }
        },
        resetPasswordRequest(state, action){
            return {
                ...state,
                loading: true,
            }
        },
        resetPasswordSuccess(state, action){
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: action.payload.data
            }
        },
        resetPasswordFail(state, action){
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        },
        listAddressRequest(state, action){
            return {
                ...state,
                loading: true,
            }
        },
        listAddressSuccess(state, action){
            return {
                ...state,
                loading: false,
                user: action.payload.data
            }
        },
        listAddressFail(state, action){
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        },
        addAddressRequest(state, action){
            return {
                ...state,
                loading: true,
                isAddressUpdated:false,
            }
        },
        addAddressSuccess(state, action){
            return {
                ...state,
                loading: false,
                isAddressUpdated : true,
                user: action.payload.data
            }
        },
        addAddressFail(state, action){
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        },
        clearaddAddress(state, action){
            return {
                ...state,
                isAddressUpdated: false
            }
        },
        deleteAddressRequest(state, action){
            return {
                ...state,
                loading: true,
                isAddressDeleted:false,
            }
        },
        deleteAddressSuccess(state, action){
            return {
                ...state,
                loading: false,
                isAddressDeleted : true,
                user: action.payload.data
            }
        },
        deleteAddressFail(state, action){
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        },
        cleardeleteAddress(state, action){
            return {
                ...state,
                isAddressDeleted: false
            }
        },
        editAddressRequest(state, action){
            return {
                ...state,
                loading: true,
                isAddressEdited:false,
            }
        },
        editAddressSuccess(state, action){
            return {
                ...state,
                loading: false,
                isAddressEdited : true,
                user: action.payload.data
            }
        },
        editAddressFail(state, action){
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        },
        cleareditAddress(state, action){
            return {
                ...state,
                isAddressEdited: false
            }
        },
        listStateRequest(state, action){
            return {
                ...state,
                loading: true,
            }
        },
        listStateSuccess(state, action){
            return {
                ...state,
                loading: false,
                state_lists: action.payload.data
            }
        },
        listStateFail(state, action){
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        },
        listCountryRequest(state, action){
            return {
                ...state,
                loading: true,
            }
        },
        listCountrySuccess(state, action){
            return {
                ...state,
                loading: false,
                country_lists: action.payload.data
            }
        },
        listCountryFail(state, action){
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        },
    }
});

const { actions, reducer } = authSlice;

export const { 
    loginRequest, 
    loginSuccess, 
    loginFail, 
    clearError,
    registerRequest, 
    registerSuccess, 
    registerFail, 
    loadUserRequest,
    loadUserSuccess,
    loadUserFail,
    logoutSuccess,
    logoutFail,
    updateProfileFail,
    updateProfileRequest,
    updateProfileSuccess,
    clearUpdateProfile,
    resetPasswordFail,
    resetPasswordRequest,
    resetPasswordSuccess,
    listAddressFail,
    listAddressRequest,
    listAddressSuccess,
    addAddressFail,
    addAddressRequest,
    addAddressSuccess,
    clearaddAddress,
    deleteAddressFail,
    deleteAddressRequest,
    deleteAddressSuccess,
    cleardeleteAddress,
    editAddressFail,
    editAddressRequest,
    editAddressSuccess,
    cleareditAddress,
    listStateFail,
    listStateRequest,
    listStateSuccess,
    listCountryFail,
    listCountryRequest,
    listCountrySuccess,
 } = actions;

export default reducer;
