import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactSlider from 'react-slider';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../actions/productActions';
import { addCartItem } from "../actions/cartActions";
import { toast } from 'react-toastify';

const ShopSection = () => {
    const [grid, setGrid] = useState(false);
    const [active, setActive] = useState(false);
    const [price, setPrice] = useState([0, 0]); 
    const [category_id, setcategory_id] = useState(0);
    const [color, setColor] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useDispatch();
    const { products, categories, colour_lists, minPrice, maxPrice,selectedminPrice, selectedmaxPrice, loading, product_count } = useSelector((state) => state.productsState);

    useEffect(() => {
        dispatch(getProducts(currentPage, category_id, price, color));
    }, [dispatch, currentPage, category_id, color]);
    useEffect(() => {
        setPrice([parseInt(selectedminPrice), parseInt(selectedmaxPrice)]);
    }, [selectedmaxPrice, selectedmaxPrice]);

    const handleChange = (newValue) => {
        setPrice(newValue);
    };
    const PriceHandle = () => {
        dispatch(getProducts(currentPage, category_id, price, color));
    }
    const sidebarController = () => {
        setActive(!active);
    };

    const resultsPerPage = 10; 
    const totalResults = product_count; 

    const startResult = (currentPage - 1) * resultsPerPage + 1;
    const endResult = Math.min(currentPage * resultsPerPage, totalResults);

    return (
        <section className="shop py-80">
            <div className={`side-overlay ${active && "show"}`}></div>
            <div className="container container-lg">
                <div className="row">
                    <div className="col-lg-3">
                        <div className={`shop-sidebar ${active && "active"}`}>
                            <button onClick={sidebarController}
                                type="button"
                                className="shop-sidebar__close d-lg-none d-flex w-32 h-32 flex-center border border-gray-100 rounded-circle hover-bg-main-600 position-absolute inset-inline-end-0 me-10 mt-8 hover-text-white hover-border-main-600"
                            >
                                <i className="ph ph-x" />
                            </button>
                            <div className="shop-sidebar__box border border-gray-100 rounded-8 p-32 mb-32">
                                <h6 className="text-xl border-bottom border-gray-100 pb-24 mb-24">
                                    Product Category
                                </h6>
                                <ul className="max-h-540 overflow-y-auto scroll-sm">
                                {categories && (
                                    categories.map((category, index) => (
                                        <li className="mb-24">
                                            <span 
                                                className="text-gray-900 hover-text-main-600"
                                                onClick={()=>{
                                                    setcategory_id(category.category_id)
                                                 }}
                                            >
                                                {category.category_name} ({category.product_count})
                                            </span>
                                        </li>
                                    ))
                                )}
                                </ul>
                            </div>
                            <div className="shop-sidebar__box border border-gray-100 rounded-8 p-32 mb-32">
                                <h6 className="text-xl border-bottom border-gray-100 pb-24 mb-24">
                                    Filter by Price
                                </h6>
                                <div className="custom--range">
                                {minPrice !== undefined && maxPrice !== undefined && (
                                    <ReactSlider
                                        className="horizontal-slider"
                                        thumbClassName="example-thumb"
                                        trackClassName="example-track"
                                        value={price}
                                        onChange={handleChange}
                                        min={minPrice}
                                        max={maxPrice}
                                        step={1}
                                        defaultValue={[parseInt(selectedminPrice), parseInt(selectedmaxPrice)]}
                                        ariaLabel={['Lower thumb', 'Upper thumb']}
                                        ariaValuetext={state => `Thumb value ${state.valueNow}`}
                                        renderThumb={(props, state) => {
                                            const { key, ...restProps } = props;
                                            return <div {...restProps} key={state.index}>{state.valueNow}</div>;
                                        }}
                                        pearling
                                        minDistance={10}
                                    />
                                )}
                                    <br />
                                    <br />
                                    <div className="flex-between flex-wrap-reverse gap-8 mt-24 ">
                                        <button type="button" className="btn btn-main h-40 flex-align" onClick={PriceHandle} >
                                            Filter{" "}
                                        </button>

                                    </div>
                                </div>
                            </div>

                            <div className="shop-sidebar__box border border-gray-100 rounded-8 p-32 mb-32">
                                <h6 className="text-xl border-bottom border-gray-100 pb-24 mb-24">
                                    Filter by Color
                                </h6>
                                <ul className="max-h-540 overflow-y-auto scroll-sm">
                                {colour_lists && (
                                    colour_lists.map((colour, index) => (
                                        <li className="mb-24">
                                            <div className="form-check common-check common-radio checked-black">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="color"
                                                    id={`color-${colour.colour_id}`}
                                                    onChange={() => setColor(colour.colour_id)}
                                                />
                                                <label className="form-check-label" htmlFor={`color-${colour.colour_id}`}>
                                                    {colour.colour_name}({colour.product_count})
                                                </label>
                                            </div>
                                        </li>
                                    ))
                                )}
                                </ul>
                            </div>
                            <div className="shop-sidebar__box rounded-8">
                                <img src="assets/images/thumbs/advertise-img1.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        {loading ? (
                            <div>Loading...</div>
                        ):(
                            <>
                                <div className="flex-between gap-16 flex-wrap mb-40 ">
                                    <span className="text-gray-900">Showing  {startResult} - {endResult} of {totalResults} result{totalResults !== 1 ? 's' : ''}</span>
                                    <div className="position-relative flex-align gap-16 flex-wrap">
                                        <div className="list-grid-btns flex-align gap-16">
                                            <button onClick={() => setGrid(true)}
                                                type="button"
                                                className={`w-44 h-44 flex-center border rounded-6 text-2xl list-btn border-gray-100 ${grid === true && "border-main-600 text-white bg-main-600"}`}
                                            >
                                                <i className="ph-bold ph-list-dashes" />
                                            </button>
                                            <button onClick={() => setGrid(false)}
                                                type="button"
                                                className={`w-44 h-44 flex-center border rounded-6 text-2xl grid-btn border-gray-100 ${grid === false && "border-main-600 text-white bg-main-600"}`}
                                            >
                                                <i className="ph ph-squares-four" />
                                            </button>
                                        </div>
                                        <div className="position-relative text-gray-500 flex-align gap-4 text-14">
                                            <label htmlFor="sorting" className="text-inherit flex-shrink-0">
                                                Sort by:{" "}
                                            </label>
                                            <select defaultValue={1}
                                                className="form-control common-input px-14 py-14 text-inherit rounded-6 w-auto"
                                                id="sorting"
                                            >
                                                <option value={1} >
                                                    Popular
                                                </option>
                                                <option value={1}>Latest</option>
                                                <option value={1}>Trending</option>
                                                <option value={1}>Matches</option>
                                            </select>
                                        </div>
                                        <button onClick={sidebarController}
                                            type="button"
                                            className="w-44 h-44 d-lg-none d-flex flex-center border border-gray-100 rounded-6 text-2xl sidebar-btn"
                                        >
                                            <i className="ph-bold ph-funnel" />
                                        </button>
                                    </div>
                                </div>
                                <div className={`list-grid-wrapper ${grid && "list-view"}`}>
                                    { products && products.map(product => (
                                        <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                                            <Link
                                                to={`/product/${product.product_seo_url}`}
                                                className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                                            >
                                                <img
                                                    src={process.env.REACT_APP_API_URL+product.product_image}
                                                    alt=""
                                                    className="w-auto max-w-unset"
                                                />
                                                <span className="product-card__badge bg-primary-600 px-8 py-4 text-sm text-white position-absolute inset-inline-start-0 inset-block-start-0">
                                                    {product.recommended_name}
                                                </span>
                                            </Link>
                                            <div className="product-card__content mt-16">
                                                <h6 className="title text-lg fw-semibold mt-12 mb-8">
                                                    <Link
                                                        to={`/product/${product.product_seo_url}`}
                                                        className="link text-line-2"
                                                        tabIndex={0}
                                                    >
                                                        {product.product_name}
                                                    </Link>
                                                </h6>
                                                <div className="product-card__price my-20">
                                                    <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                                                    ₹{product.list_price}
                                                    </span>
                                                    <span className="text-heading text-md fw-semibold " style={{"marginLeft": "15px"}}>
                                                    ₹{product.selling_price} {" "}
                                                    </span>
                                                </div>
                                                <Link
                                                    onClick={()=>{
                                                        dispatch(addCartItem(product.product_option_id, 1))
                                                        toast('Cart Item Added!',{
                                                            type: 'success',
                                                            position: toast.POSITION.TOP_RIGHT
                                                        })
                                                    }}
                                                    className="product-card__cart btn bg-gray-50 text-heading hover-bg-main-600 hover-text-white py-11 px-24 rounded-8 flex-center gap-8 fw-medium"
                                                    tabIndex={0}
                                                >
                                                    Add To Cart <i className="ph ph-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                    
                                </div>
                            </>
                        )
                        }   
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ShopSection;