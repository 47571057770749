import React,{ useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { logout,updateProfile,clearAuthError,resetPassword,getAddress,getStateList,getCountryList,addAddress,deleteAddress,editAddress  } from '../actions/userActions';
import { clearUpdateProfile,clearaddAddress,cleardeleteAddress,cleareditAddress } from "../slices/authSlice";
import { toast } from 'react-toastify';
import '../css/profile.css'
const Profile = () => {
    const [section, setSection] = useState('MY ACCOUNT');
    const [password, setPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);
    const { error, isAuthenticated,user,isUpdated,state_lists,country_lists,isAddressUpdated,isAddressDeleted,isAddressEdited } = useSelector((state) => state.authState);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClick = (param) => {
        if(param === 'LOGOUT'){
            dispatch(logout);
        }else if(param === 'ADDRESS BOOK'){
            dispatch(getAddress);
            setSection(param)
        }else if(param === 'ADD NEW ADDRESS'){
            dispatch(getStateList);
            dispatch(getCountryList);
            setSection(param)
        }else{
            setSection(param)
        }
    };
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/account')
        }
        if(isUpdated) {
            toast('Profile updated successfully',{
                type: 'success',
                position: toast.POSITION.TOP_RIGHT,
                onOpen: () => dispatch(clearUpdateProfile())
            })
            setSection('MY ACCOUNT')
        }

        if(isAddressDeleted){
            toast('Address Deleted successfully',{
                type: 'success',
                position: toast.POSITION.TOP_RIGHT,
                onOpen: () => dispatch(cleardeleteAddress())
            })
            dispatch(getAddress);
            setSection('ADDRESS BOOK')
        }

        if(isAddressEdited){
            toast('Address Updated successfully',{
                type: 'success',
                position: toast.POSITION.TOP_RIGHT,
                onOpen: () => dispatch(cleareditAddress())
            })
            setEditAddressData({
                customer_address_id : 0,
                name: '',
                address_1: '',
                address_2: '',
                city: '',
                state_id_fk: 0,
                country_id_fk: 0,
                pincode: '',
                mobile_no: '',
            });
            setSelectedEditState(0);
            setSelectedEditCountry(0);
            dispatch(getAddress);
            setSection('ADDRESS BOOK')
        }

        if(isAddressUpdated){
            toast('New Address Added successfully',{
                type: 'success',
                position: toast.POSITION.TOP_RIGHT,
                onOpen: () => dispatch(clearaddAddress())
            })
            setNewAddressData({
                name: '',
                address_1: '',
                address_2: '',
                city: '',
                state_id_fk: 0,
                country_id_fk: 0,
                pincode: '',
                mobile_no: '',
            });
            setSelectedState(0);
            setSelectedCountry(0);
            dispatch(getAddress);
            setSection('ADDRESS BOOK')
        }

        if (error) {
            toast(error, {
                position: toast.POSITION.TOP_RIGHT,
                type : "error",
                onOpen: ()=> { dispatch(clearAuthError) }
            });
        }
    }, [error, isAuthenticated,navigate,isUpdated,dispatch,isAddressUpdated,isAddressDeleted,isAddressEdited]);
    const [userData, setUserData] = useState({
        firstname: user?.first_name,
        lastname: user?.last_name,
        mobileno: user?.mobile_no,
        whatsappno: user?.whatsapp_no,
        email: user?.email_id,
    });
    const onChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };
    const [newAddressData, setNewAddressData] = useState({
        name: '',
        address_1: '',
        address_2: '',
        city: '',
        state_id_fk: 0,
        country_id_fk:0,
        pincode : '',
        mobile_no : ''
    });
    const onChangeAddress = (e) => {
        setNewAddressData({ ...newAddressData, [e.target.name]: e.target.value });
    };
    const [selectedState, setSelectedState] = useState(0);

    // Step 2: Handle the change event when the user selects a state
    const handleStateChange = (e) => {
        setSelectedState(e.target.value);  // Update the state with the selected value
    };
    const [selectedCountry, setSelectedCountry] = useState(0);

    // Step 2: Handle the change event when the user selects a state
    const handleCountryChange = (e) => {
        setSelectedCountry(e.target.value);  // Update the state with the selected value
    };
    const userUpdateHandler = (e) => {
        e.preventDefault();
        if (!userData.firstname || !userData.lastname || !userData.email || !userData.mobileno) {
            toast.error('Please fill in all required fields.', {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        const updateData = {
            first_name : userData.firstname,
            last_name : userData.lastname,
            mobile_no : userData.mobileno,
            whatsapp_no : userData.whatsappno,
            email_id : userData.email,
        }
        dispatch(updateProfile(updateData));
    }
    const setPasswordHandler  = (e) => {
        e.preventDefault();
        if (!password || !confirmPassword) {
            toast.error('Please fill in all required fields.', {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        if (password !== confirmPassword) {
            toast.error('Passwords do not match', {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        if (password === confirmPassword) {
            dispatch(resetPassword(password,confirmPassword));
            setSection('MY ACCOUNT')
        }
    }
    const addAddressHandler = (e) => {
        e.preventDefault();
        if (!newAddressData.name || !newAddressData.address_1 || !newAddressData.address_2 || !newAddressData.city || !selectedState || !selectedCountry || !newAddressData.pincode || !newAddressData.mobile_no) {
            toast.error('Please fill in all required fields.', {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        const addressData = {
            name : newAddressData.name,
            address_1 : newAddressData.address_1,
            address_2 : newAddressData.address_2,
            city : newAddressData.city,
            state_id_fk : selectedState,
            country_id_fk : selectedCountry,
            pincode : newAddressData.pincode,
            mobile_no : newAddressData.mobile_no,
        }
        dispatch(addAddress(addressData));
        
    }
    const handleDelete = async (address_id) => {
        dispatch(deleteAddress(address_id));
    }
    const [editAddressData, setEditAddressData] = useState({
        customer_address_id: 0,
        name: '',
        mobile_no: '',
        address_1: '',
        address_2: '',
        city: '',
        state_id_fk: '',
        country_id_fk: '',
        pincode: '',
    });
    const [selectedEditState, setSelectedEditState] = useState(0);
    const onChangeEditAddress = (e) => {
        setEditAddressData({ ...editAddressData, [e.target.name]: e.target.value });
    };

    // Step 2: Handle the change event when the user selects a state
    const handleEditStateChange = (e) => {
        setSelectedEditState(e.target.value);  // Update the state with the selected value
    };
    const [selectedEditCountry, setSelectedEditCountry] = useState(0);

    // Step 2: Handle the change event when the user selects a state
    const handleEditCountryChange = (e) => {
        setSelectedEditCountry(e.target.value);  // Update the state with the selected value
    };
    const handleEditAddress = async (address) => {
        setEditAddressData({
            customer_address_id: address.customer_address_id,
            name: address.name,
            mobile_no: address.mobile_no,
            address_1: address.address_1,
            address_2: address.address_2,
            city: address.city,
            pincode: address.pincode,
        });
        setSelectedEditState(address.state_id_fk);
        setSelectedEditCountry(address.country_id_fk);
        dispatch(getStateList);
        dispatch(getCountryList);
        setSection('EDIT ADDRESS')
    }
    const editAddressHandler = (e) => {
        e.preventDefault();
        if (!editAddressData.name || !editAddressData.address_1 || !editAddressData.address_2 || !editAddressData.city || !selectedEditState || !selectedEditCountry || !editAddressData.pincode || !editAddressData.mobile_no) {
            toast.error('Please fill in all required fields.', {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        const editaddressData = {
            customer_address_id : editAddressData.customer_address_id,
            name : editAddressData.name,
            address_1 : editAddressData.address_1,
            address_2 : editAddressData.address_2,
            city : editAddressData.city,
            state_id_fk : selectedEditState,
            country_id_fk : selectedEditCountry,
            pincode : editAddressData.pincode,
            mobile_no : editAddressData.mobile_no,
        }
        dispatch(editAddress(editaddressData));
        
    }
    return (
        <section className="cart py-80">
            <div className="container container-lg">
                <div className="row gy-4">
                    <div className="col-xl-9 col-md-12 col-sm-12 col-xs-12">
                    {section === 'MY ACCOUNT' && (
                        <section class="profile py-5">
                            <div class="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="card shadow-lg border-0 rounded-lg">
                                            <div className="card-body">
                                                <div className="text-center mb-4">
                                                    <h4 className="card-title fw-bold">{user?.first_name} {user?.last_name}</h4>
                                                    <p className="text-muted">{user?.email_id}</p>
                                                </div>
                                                <hr className="bottom-line"/>
                                                <div className="row mb-4">
                                                    <div className="col-md-6">
                                                        <h6 className="fw-bold">Mobile No:</h6>
                                                        <p className="text-muted">{user?.mobile_no || 'N/A'}</p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h6 className="fw-bold">Whatsapp No:</h6>
                                                        <p className="text-muted">{user?.whatsapp_no || 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <hr className="bottom-line"/>
                                                <div className="text-right">
                                                    <button className="btn btn-danger" onClick={() => handleClick('LOGOUT')}>
                                                        Logout
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                    {section === 'EDIT ACCOUNT' && (
                        <div className="shadow-lg border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40 h-100">
                            <h6 className="text-xl mb-32">Edit Account</h6>
                            <form onSubmit={userUpdateHandler}>
                                <div className="row">
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="firstnameTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            First Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="firstnameTwo"
                                            name="firstname"
                                            value={userData.firstname}
                                            placeholder="Write a firstname"
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="lastnameTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Last Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="lastnameTwo"
                                            name="lastname"
                                            value={userData.lastname}
                                            placeholder="Write a lastname"
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="emailTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Email address <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            className="common-input"
                                            id="emailTwo"
                                            name="email"
                                            value={userData.email}
                                            placeholder="Enter Email Address"
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="mobile_noTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Mobile No. <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="mobile_noTwo"
                                            name="mobileno"
                                            value={userData.mobileno}
                                            placeholder="Write a mobile no"
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="whatsapp_noTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Whatsapp No 
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="whatsapp_noTwo"
                                            name="whatsappno"
                                            value={userData.whatsappno}
                                            placeholder="Write a whatsapp no"
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <button type="submit" className="text-right btn btn-main py-18 px-40">
                                            UPDATE
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                    {section === 'PASSWORD' && (
                        <section class="profile py-5">
                            <div class="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="card shadow-lg border-0 rounded-lg">
                                            <div className="card-body">
                                                <form onSubmit={setPasswordHandler} className="shadow-lg">
                                                    <div className="row">
                                                        <div className="mb-24 col-md-12">
                                                            <label
                                                                htmlFor="password"
                                                                className="text-neutral-900 text-lg mb-8 fw-medium"
                                                            >
                                                                Password
                                                            </label>
                                                            <input
                                                                type="password"
                                                                id="password_field"
                                                                className="form-control"
                                                                value={password}
                                                                onChange={e => setPassword(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="mb-24 col-md-12">
                                                            <label
                                                                htmlFor="confirm_password_field"
                                                                className="text-neutral-900 text-lg mb-8 fw-medium"
                                                            >
                                                                Confirm Password
                                                            </label>
                                                            <input
                                                                type="password"
                                                                id="confirm_password_field"
                                                                className="form-control"
                                                                value={confirmPassword}
                                                                onChange={e => setConfirmPassword(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <hr className="bottom-line"/>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <button type="submit" className="text-right btn btn-danger" >
                                                                Set Password
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                    {section === 'ADDRESS BOOK' && (
                        <div className="shadow-lg border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40 h-100">
                        <h6 className="text-xl mb-32">Address List</h6>
                            <div className="row">
                                <div className="mb-24 col-md-12">
                                    <div className="table-responsive">
                                        <table className=" table-striped table-hover profile_table">
                                            <thead className="table-light text-center">
                                            <tr>
                                                <th>Name</th>
                                                <th>Mobile No</th>
                                                <th>Address</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody className="text-center">
                                                { user?.address_lists?.length > 0 ? (
                                                    user.address_lists.map((address, index) => (
                                                        <tr key={index}>
                                                            <td>{address.name}</td>
                                                            <td>{address.mobile_no}</td>
                                                            <td>{address.address_1}, {address.address_2}, {address.city}, {address.state_name}, {address.country_name}, {address.pincode}</td>
                                                            <td>
                                                                <button className="btn btn-primary btn-sm me-2" onClick={() => handleEditAddress(address)}>Edit</button>
                                                                <button className="btn btn-danger btn-sm" onClick={() => handleDelete(address.customer_address_id)}>Delete</button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4" className="text-center">
                                                            No address found.
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="text-right">
                                        <button type="submit" onClick={() => handleClick('ADD NEW ADDRESS')} className="btn btn-primary" >
                                            Add new address
                                        </button>
                                    </div>
                                </div>
                            </div>
                       </div>
                      
                    )}
                    {section === 'ADD NEW ADDRESS' && (
                        <div className="shadow-lg border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40 h-100">
                            <h6 className="text-xl mb-32">Add New Address</h6>
                            <form onSubmit={addAddressHandler}>
                                <div className="row">
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="nameTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="nameTwo"
                                            name="name"
                                            value={newAddressData.name}
                                            placeholder="Write a name"
                                            onChange={onChangeAddress}
                                        />
                                    </div>
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="address_1Two"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Address Line 1 <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="address_1Two"
                                            name="address_1"
                                            value={newAddressData.address_1}
                                            placeholder="Write a Address Line 1"
                                            onChange={onChangeAddress}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="address_2Two"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Address Line 2 <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="address_2"
                                            className="common-input"
                                            id="address_2Two"
                                            name="address_2"
                                            value={newAddressData.address_2}
                                            placeholder="Write a Address Line 2"
                                            onChange={onChangeAddress}
                                        />
                                    </div>
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="cityTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            City <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="cityTwo"
                                            name="city"
                                            value={newAddressData.city}
                                            placeholder="Write a City"
                                            onChange={onChangeAddress}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="state_id_fkTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            State Name <span className="text-danger">*</span>
                                        </label>
                                        <select className="common-input"
                                            id="state_id_fkTwo"
                                            name="state_id_fk"
                                            value={selectedState}
                                            onChange={handleStateChange}
                                        >
                                                <option value={0}>-Select-</option>
                                                {state_lists?.length > 0 &&
                                                    state_lists.map((state) => (
                                                        <option key={state.state_id} value={state.state_id}>
                                                            {state.state_name}
                                                        </option>
                                                    ))}
                                            </select>
                                    </div>
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="country_id_fkTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Country Name <span className="text-danger">*</span>
                                        </label>
                                        <select className="common-input"
                                            id="country_id_fkTwo"
                                            name="country_id_fk"
                                            value={selectedCountry}
                                            onChange={handleCountryChange}
                                        >
                                                <option value={0}>-Select-</option>
                                                {country_lists?.length > 0 &&
                                                    country_lists.map((country) => (
                                                        <option key={country.country_id} value={country.country_id}>
                                                            {country.country_name}
                                                        </option>
                                                    ))}
                                            </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="pincodeTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Pincode <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="pincode"
                                            name="pincode"
                                            value={newAddressData.pincode}
                                            placeholder="Write a pincode"
                                            onChange={onChangeAddress}
                                        />
                                    </div>
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="mobile_noTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Mobile No. <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="mobile_noTwo"
                                            name="mobile_no"
                                            value={newAddressData.mobile_no}
                                            placeholder="Write a Mobile No"
                                            onChange={onChangeAddress}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <button type="submit" className="text-right btn btn-main py-18 px-40">
                                            ADD 
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                    {section === 'EDIT ADDRESS' && (
                        <div className="shadow-lg border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40 h-100">
                            <h6 className="text-xl mb-32">Edit Address</h6>
                            <form onSubmit={editAddressHandler}>
                                <div className="row">
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="nameTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="nameTwo"
                                            name="name"
                                            value={editAddressData.name}
                                            placeholder="Write a name"
                                            onChange={onChangeEditAddress}
                                        />
                                    </div>
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="address_1Two"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Address Line 1 <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="address_1Two"
                                            name="address_1"
                                            value={editAddressData.address_1}
                                            placeholder="Write a Address Line 1"
                                            onChange={onChangeEditAddress}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="address_2Two"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Address Line 2 <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="address_2"
                                            className="common-input"
                                            id="address_2Two"
                                            name="address_2"
                                            value={editAddressData.address_2}
                                            placeholder="Write a Address Line 2"
                                            onChange={onChangeEditAddress}
                                        />
                                    </div>
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="cityTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            City <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="cityTwo"
                                            name="city"
                                            value={editAddressData.city}
                                            placeholder="Write a City"
                                            onChange={onChangeEditAddress}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="state_id_fkTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            State Name <span className="text-danger">*</span>
                                        </label>
                                        <select className="common-input"
                                            id="state_id_fkTwo"
                                            name="state_id_fk"
                                            value={selectedEditState}
                                            onChange={handleEditStateChange}
                                        >
                                                <option value={0}>-Select-</option>
                                                {state_lists?.length > 0 &&
                                                    state_lists.map((state) => (
                                                        <option key={state.state_id} value={state.state_id}>
                                                            {state.state_name}
                                                        </option>
                                                    ))}
                                            </select>
                                    </div>
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="country_id_fkTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Country Name <span className="text-danger">*</span>
                                        </label>
                                        <select className="common-input"
                                            id="country_id_fkTwo"
                                            name="country_id_fk"
                                            value={selectedEditCountry}
                                            onChange={handleEditCountryChange}
                                        >
                                                <option value={0}>-Select-</option>
                                                {country_lists?.length > 0 &&
                                                    country_lists.map((country) => (
                                                        <option key={country.country_id} value={country.country_id}>
                                                            {country.country_name}
                                                        </option>
                                                    ))}
                                            </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="pincodeTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Pincode <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="pincode"
                                            name="pincode"
                                            value={editAddressData.pincode}
                                            placeholder="Write a pincode"
                                            onChange={onChangeEditAddress}
                                        />
                                    </div>
                                    <div className="mb-24 col-md-6">
                                        <label
                                            htmlFor="mobile_noTwo"
                                            className="text-neutral-900 text-lg mb-8 fw-medium"
                                        >
                                            Mobile No. <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="common-input"
                                            id="mobile_noTwo"
                                            name="mobile_no"
                                            value={editAddressData.mobile_no}
                                            placeholder="Write a Mobile No"
                                            onChange={onChangeEditAddress}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <button type="submit" className="text-right btn btn-main py-18 px-40">
                                            UPDATE 
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                    </div>
                    <div className="col-xl-3 col-md-12 col-sm-12 col-xs-12 content-aside right_column sidebar-offcanvas">
                        <div class="module">
                        <h3 class="modtitle"><span>My Account </span></h3>
                        <div class="module-content custom-border">
                            <ul class="list-box">
                            
                            <li><button   className={section === 'MY ACCOUNT' ? 'active' : ''} onClick={() => handleClick('MY ACCOUNT')}>My Account </button ></li>
                            
                            <li><button   className={section === 'EDIT ACCOUNT' ? 'active' : ''} onClick={() => handleClick('EDIT ACCOUNT')}>Edit Account </button ></li>
                            <li><button   className={section === 'PASSWORD' ? 'active' : ''} onClick={() => handleClick('PASSWORD')}>Password </button ></li>
                            
                            <li><button    className={section === 'ADDRESS BOOK' ? 'active' : ''}  onClick={() => handleClick('ADDRESS BOOK')}>Address Book </button ></li>
                            <li><button   className={section === 'WISHLIST' ? 'active' : ''} onClick={() => handleClick('WISHLIST')}>Wish List </button ></li>
                            <li><button   className={section === 'ORDER HISTORY' ? 'active' : ''} onClick={() => handleClick('ORDER HISTORY')}>Order History </button ></li>
                            <li><button   className={section === 'LOGOUT' ? 'active' : ''} onClick={() => handleClick('LOGOUT')}>Logout </button ></li>
                            
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Profile;
